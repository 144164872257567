import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { StorageService } from '../../../core/services/storage/storage.service';
import { PwaInstallationModalService } from './pwa-installation-modal.service';
import { pwaInstallPromptKey, pwaInstallPromptLifespanInHours } from './pwa.config.json';
@Injectable({
	providedIn: 'root',
})
export class PwaInstallService {
	private promptEvent: any;

	private async promptRecentlyInvalidated() {
		const lastInvalidation = await this.storage.loadFromStorage<number>(pwaInstallPromptKey).pipe(take(1)).toPromise();
		return (
			lastInvalidation !== null &&
			lastInvalidation > new Date().getTime() - pwaInstallPromptLifespanInHours * 60 * 60 * 1000
		);
	}

	/**
	 * Summons a prompt with info / actions to install the app as PWA.
	 * @param respectUserPreference whether or not to respect a user's preference to defer the prompt for `pwaInstallPromptLifespanInHours` hours. Can be set to `false` when an install is requested through the UI by the use.
	 */
	public async summonPwaPrompt(respectUserPreference = true) {
		const recentlyInvalidatedPrompt = await this.promptRecentlyInvalidated();

		if (this.platform.ANDROID) {
			if (!respectUserPreference || !recentlyInvalidatedPrompt) {
				return this.openPromptComponent('android');
			}
		}
		if (this.platform.IOS) {
			if (!this.isInStandaloneMode && (!respectUserPreference || !recentlyInvalidatedPrompt)) {
				return this.openPromptComponent('ios');
			}
		}

		if (!respectUserPreference) {
			return this.openPromptComponent('android');
		}
	}

	public get isInStandaloneMode() {
		return (
			window.matchMedia('(display-mode: standalone)').matches ||
			('standalone' in window.navigator && window.navigator['standalone']) ||
			document.referrer.includes('android-app://')
		);
	}

	public get isInDesktopMode() {
		return !window.matchMedia('only screen and (max-width: 760px)').matches || !/Mobi/.test(navigator.userAgent);
	}

	private openPromptComponent(mobileType: 'ios' | 'android') {
		return this.pwaInstallModal.show({
			platform: mobileType,
			promptEvent: this.promptEvent,
		});
	}

	public deferInstallationPrompt() {
		window.addEventListener('beforeinstallprompt', async (event: any) => {
			event.preventDefault();
			this.promptEvent = event;
		});
	}

	constructor(
		private platform: Platform,
		private storage: StorageService,
		private pwaInstallModal: PwaInstallationModalService
	) {}
}
