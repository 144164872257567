import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '@env';
import { throwIfAlreadyLoaded } from './guard/module-import.guard';
import { FirebaseAuthInterceptorProvider } from './interceptors/auth.interceptor';

@NgModule({
	declarations: [],
	imports: [AngularFireModule.initializeApp(environment.firebase), AngularFirestoreModule],
	providers: [FirebaseAuthInterceptorProvider],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}
}
