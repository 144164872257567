import { Component, forwardRef, Input, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputFieldComponent),
	multi: true,
};

@Component({
	selector: 'app-input-field',
	templateUrl: './input-field.component.html',
	styleUrls: ['./input-field.component.scss'],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class InputFieldComponent implements ControlValueAccessor {
	constructor() {}

	@Input() label = '';
	@Input() placeholder = 'Placeholder';
	@Input() type: 'text' | 'email' | 'password' | 'tel' | 'number' | 'search' = 'text';
	@Input() error = false;
	@Input() errorMessage = '';
	@Input() reserveErrorSpace = true;
	@Input() theme: 'default' | 'small' = 'default';
	@Input() readonly = false;

	// tslint:disable-next-line: variable-name
	private _value: any = '';

	private onTouchedCallback: () => void = () => {};
	private onChangeCallback: (_: any) => void = () => {};

	get value() {
		return this._value;
	}
	set value(v: any) {
		if (v !== this._value) {
			this._value = v;
			this.onChangeCallback(v);
		}
	}

	onBlur() {
		this.onTouchedCallback();
	}

	writeValue(value: any): void {
		this._value = value;
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}
}
