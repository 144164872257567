import { Component, ElementRef, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@core/services/auth/auth.service';
import { AppUpdateService } from '@core/services/pwa/app-update.service';
import { environment } from '@env';
import { NotificationService } from 'shared-library';
import { PwaInstallService } from './modules/onboarding/pwa-installation-modal/pwa-install.service';

type TokenChangeState = firebase.User & { h?: { code: string } };

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	showNavigation$ = this.authService.isAuthenticated$;
	deferredTokenChangeState: TokenChangeState;

	ngOnInit() {
		this.titleService.setTitle(`Ratio Monitor ${environment.name}`);
	}

	// 🤓 Grote hack. Kan kapot gaan.
	observeUserBan() {
		this.authService.onIdTokenChanged((change: TokenChangeState) => {
			if (change) {
				// we deferren de change, omdat de waarde 'h' pas geset wordt wanneer
				// we gebanned worden, en deze dus pas toegankelijk is nadat
				// er eerder een waarde op 'change' heeft gezeten, maar bij de huidige change
				// de waarde null is.
				this.deferredTokenChangeState = change;
			} else if (change == null && this.deferredTokenChangeState) {
				const deferred = this.deferredTokenChangeState;
				// We gaan hier buiten de boekjes, change.h is private, dus dit zou kapot kunnen gaan.
				if (deferred && deferred.h && deferred.h.code === 'auth/user-disabled') {
					setTimeout(() => {
						this.notification.show(this.elementRef, {
							headline: 'Account geblokkeerd',
							subtext: 'Uw account is door een administrator geblokkeerd.',
						});
						this.authService.routeToLogin();
					}, 2000);
				}
			}
		});
	}

	constructor(
		private authService: AuthService,
		private notification: NotificationService,
		public elementRef: ElementRef,
		private readonly pwaUpdate: AppUpdateService,
		private readonly pwaInstaller: PwaInstallService,
		public titleService: Title
	) {
		this.observeUserBan();
		this.pwaUpdate.connectToElementRef(elementRef);
		this.pwaInstaller.deferInstallationPrompt();
	}
}
