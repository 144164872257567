export const passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const AuthErrorMap = {
	'auth/user-not-found': 'Er kan geen gebruiker met dit email adres gevonden worden.',
	'auth/email-already-in-use': 'Dit emailadres is al in gebruik.',
	'auth/invalid-email': 'Het opgegeven emailadres is geen valide emailadres.',
	'auth/invalid-password': 'Het opgegeven wachtwoord is niet correct.',
	'auth/user-disabled': 'Dit gebruikersaccount is door een administrator geblokkeerd.',
	'password/pattern':
		'Het wachtwoord moet minimaal 8 karakters waarvan minimaal 1 hoofdletter, 1 nummer en 1 speciaal teken bevatten.',
	'password/confirm': 'De opgegeven wachtwoorden komen niet overeen.',
	'email/invalid': 'Het opgegeven emailadres is geen valide emailadres.',
	'auth/register-forbidden':
		'U kunt met dit emailadres helaas geen account aanmaken, omdat hij niet in onze gebruikerslijst present is.',
	'auth/login-forbidden':
		'Het ingevoerde emailadres is niet present in onze gebruikerslijst, daarom kunt u niet inloggen.',
};
export const ControlInvalidDuration = 5000;
