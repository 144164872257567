import { Component, HostBinding, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { staticAnimation } from '@core/animations';
import { conditionalDebounceTime } from '@core/classes/conditionalDebounceTime.class';
import { AuthService } from '@core/services/auth/auth.service';
import { passwordPattern } from 'app/config/auth.config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const loginFormValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
	const errors: ValidationErrors = {};

	const email = control.get('email');
	const password = control.get('password');

	const fieldInvalid = (field: AbstractControl) => field.value && field.dirty && field.invalid;

	if (email && password) {
		if (fieldInvalid(email)) {
			errors.email = true;
		}
		if (fieldInvalid(password)) {
			errors.password = true;
		}
	}

	return errors;
};

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	animations: [staticAnimation],
})
export class LoginComponent implements OnInit {
	@HostBinding('@static') get static() {
		return '';
	}

	// tslint:disable-next-line: member-ordering
	loginForm = new FormGroup(
		{
			email: new FormControl('', { validators: [Validators.email], updateOn: 'blur' }),
			password: new FormControl('', { validators: [Validators.pattern(passwordPattern)] }),
		},
		{ validators: loginFormValidator }
	);
	passwordError$: Observable<boolean>;

	clearError(error: string) {
		const errs = this.loginForm.errors;
		if (errs && errs[error]) {
			const { [error]: undefined, ...remainingErrors } = errs;
			this.loginForm.setErrors(remainingErrors);
		}
	}

	async loginEmailPassword() {
		await this.ensureAutocompleteValuesPresent();

		if (!this.loginForm.valid) {
			return void 0;
		}

		const formValue = this.loginForm.getRawValue();
		const { email, password } = formValue;

		// before we sign in, we verify our email is correct.
		const validityCheck = await this.auth.verifyEmail(email).toPromise();

		if (validityCheck.valid === false) {
			this.auth.setErrorOnControl(this.loginForm, { code: 'auth/login-forbidden' }, 'auth');
		} else {
			this.auth
				.signInWithEmailAndPassword(email, password)
				.then(() => this.router.navigate(['map']))
				.catch(error => this.auth.setErrorOnControl(this.loginForm, error));
		}
	}

	/**
	 * Forces a re-evaluation of the email and password field that updates the value of the form control.
	 * Useful to ensure autofilled passwords are found when a user clicks the login button as first
	 * action in the UI on boot of the application.
	 */
	private ensureAutocompleteValuesPresent() {
		this.loginForm.updateValueAndValidity();
	}

	observePasswordChanges() {
		const passwordField = this.loginForm.get('password') as AbstractControl;
		this.passwordError$ = passwordField.valueChanges.pipe(
			conditionalDebounceTime(() => passwordField.invalid, 500),
			map(() => passwordField.invalid)
		);
	}

	ngOnInit(): void {
		this.observePasswordChanges();
	}

	constructor(public auth: AuthService, private router: Router) {}
}
