import { Component, forwardRef, Input, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => PasswordFieldComponent),
	multi: true,
};

@Component({
	selector: 'app-password-field',
	templateUrl: './password-field.component.html',
	styleUrls: ['./password-field.component.scss'],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class PasswordFieldComponent implements ControlValueAccessor {
	@Input() label = 'Label';
	@Input() placeholder = 'Placeholder';
	@Input() error = false;
	@Input() errorMessage = '';

	// tslint:disable-next-line: variable-name
	private _value: any = '';
	fieldType: 'text' | 'password' = 'password';

	private onTouchedCallback: () => void = () => {};
	private onChangeCallback: (_: any) => void = () => {};

	get value() {
		return this._value;
	}
	set value(v: any) {
		if (v !== this._value) {
			this._value = v;
			this.onChangeCallback(v);
		}
	}

	onBlur() {
		this.onTouchedCallback();
	}

	writeValue(value: any): void {
		this._value = value;
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setFieldType(type: 'text' | 'password' = 'password') {
		this.fieldType = type;
	}

	constructor() {}
}
