<div class="backdrop" [@backdropAnimation]="animationState" (click)="close()"></div>
<div
	id="pwa-installation-modal"
	class="panel"
	[@panelAnimation]="animationState"
	(@panelAnimation.start)="onAnimationStart($event)"
	(@panelAnimation.done)="onAnimationDone($event)"
>
	<div class="info-panel">
		<div class="logo-area">
			<img src="/assets/icons/icon.svg" alt="Ratio monitor logo" />
		</div>

		<h5 class="is-h5">Ratio monitor installeren?</h5>
		<p class="is-paragraph-text">
			Installeer Ratio monitor op uw thuisscherm voor snelle, gemakkelijke toegang tot al uw meetgegevens.
		</p>
	</div>

	<div class="platform-action" [ngSwitch]="data.platform">
		<div *ngSwitchCase="'ios'">
			<p class="ios-action">
				Klik op <span><img src="/assets/Share.svg" alt="iOS Share icon" /></span>, dan op 'Zet op beginscherm'.
			</p>
		</div>
		<div *ngSwitchCase="'android'">
			<div class="android-action">
				<button class="cancel-button" (click)="close()">Niet nu</button>
				<button class="install-button" (click)="install()">Installeren</button>
			</div>
		</div>
	</div>
</div>
