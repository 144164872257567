<button [disabled]="disabled" [class.inverted]="inverted" role="button">
	<div class="icon icon-left">
		<ng-content select="[icon-left]"></ng-content>
	</div>
	<div class="label">
		<p class="is-h5">{{ label }}</p>
	</div>
	<div class="icon icon-right">
		<ion-icon name="arrow-forward-outline"></ion-icon>
	</div>
</button>
