import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Woning } from '@core/classes/woning.class';
import { environment } from '@env';
import { ErrorFromBackend, SensorValueChecked, TiltmeterRangeArgs, Zetting } from 'shared';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	url = environment.apiEndpoint;
	// url = 'http://localhost:8080';

	public testAlarmen = {
		get: (woningId: string, sensorId: string, rangeStart: string, rangeEnd: string) => {
			return this.http.get<{ message: string; data: SensorValueChecked[] } | ErrorFromBackend | Zetting>(
				`${this.url}/alarmen`,
				{
					params: {
						woningId,
						sensorId,
						rangeStart,
						rangeEnd,
					},
				}
			);
		},
	};

	public tiltmeters = {
		range: ({ measurepoint, rangeStart, rangeEnd }: TiltmeterRangeArgs) => {
			return this.http.get<{ x: string; y: number }[]>(`${this.url}/tiltmeters/range`, {
				params: {
					measurepoint,
					rangeStart,
					rangeEnd,
				},
			});
		},
	};

	public rondrekening = {
		range: (woningId: string, rangeStart: string, rangeEnd: string) => {
			return this.http.get<any>(`${this.url}/rondrekening`, {
				params: {
					woningId,
					rangeStart,
					rangeEnd,
				},
			});
		},
	};

	public sensors = {
		woningSensors: ({ sensorId, rangeStart, rangeEnd }) => {
			return this.http.get<any>(`${this.url}/woning/sensors`, {
				params: {
					sensorId,
					rangeStart,
					rangeEnd,
				},
			});
		},
	};

	public relatieverotatie = {
		get: ({ sensorId, rangeStart, rangeEnd }) => {
			return this.http.get<any>(`${this.url}/relrotatie/`, {
				params: {
					sensorId,
					rangeStart,
					rangeEnd,
				},
			});
		},
	};

	public auth = {
		setCustomClaims: () => {
			return this.http.get(`${this.url}/auth/setToken`);
		},
		validateEmail: (email: string) => {
			return this.http.get<{ email: string; valid: boolean }>(`${this.url}/auth/validateEmail`, { params: { email } });
		},
	};

	constructor(private http: HttpClient) {}
}
