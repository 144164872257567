import { Injectable } from '@angular/core';
import { Observable, merge, iif, of } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { tap, filter, map, mergeMap } from 'rxjs/operators';

class CacheStrategyOptions {
	/**
	 * When set to true, the service will return only return data from the cache, if present.
	 * Else, if no data is present in the cache, will allow to write and retrieve from the source Observable.
	 */
	defaultToCache = false;

	constructor(options?: CacheStrategyOptions) {
		if (options) {
			this.defaultToCache = options.defaultToCache;
		}
	}
}

/**
 * This Service wraps around '@ngx-pwa/local-storage' and provides high-level control over indexedDB.
 */
@Injectable({
	providedIn: 'root',
})
export class StorageService {
	public useCacheStrategy<T = string>(
		observable: Observable<T>,
		key: string,
		options = new CacheStrategyOptions()
	): Observable<T> {
		if (options.defaultToCache) {
			return this.loadFromStorage<T>(key).pipe(
				mergeMap(storedData =>
					iif(() => !!storedData, of(storedData), observable.pipe(tap(data => this.writeToStorage(key, data))))
				)
			);
		}

		return merge(
			this.loadFromStorage<T>(key).pipe(filter(value => !!value)),
			observable.pipe(tap(data => this.writeToStorage(key, data)))
		);
	}

	public clearStorage() {
		return this.storageMap.clear();
	}

	public async writeToStorage(key: string, data: any) {
		await this.storageMap.set(key, data).toPromise();
	}

	public loadFromStorage<T = string>(key: string) {
		return this.storageMap.get<T>(key) as Observable<T>;
	}

	public watch<T = string>(key: string) {
		return this.storageMap.watch(key) as Observable<T>;
	}

	constructor(private storageMap: StorageMap) {}
}
