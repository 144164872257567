<app-auth-layout>
	<ng-container hero>
		<div id="header">
			<app-back-button [route]="'/login'" text="Terug naar login"></app-back-button>
		</div>
	</ng-container>
	<ng-container form>
		<h5 class="is-h5">Registreren</h5>
		<form [formGroup]="registerForm">
			<app-input-field
				formControlName="email"
				[error]="registerForm.errors?.email"
				[errorMessage]="auth.errorMap['email/invalid']"
				type="email"
				placeholder="email@address.com"
				label="Email adres"
			>
				<ng-container icon-before>
					<ion-icon name="mail"></ion-icon>
				</ng-container>
			</app-input-field>
			<app-password-field
				formControlName="password"
				[error]="registerForm.errors?.password"
				[errorMessage]="auth.errorMap['password/pattern']"
				placeholder=""
				(focusin)="clearError('password')"
				label="Wachtwoord"
			>
			</app-password-field>
			<app-password-field
				formControlName="passwordconfirm"
				[error]="registerForm.errors?.passwordconfirm"
				[errorMessage]="auth.errorMap['password/confirm']"
				placeholder=""
				label="Wachtwoord herhalen"
			>
			</app-password-field>
			<p class="is-paragraph is-error">
				<span *ngIf="registerForm.errors?.provider">{{ registerForm.errors?.provider }}</span>
				<span *ngIf="registerForm.errors?.auth">{{ registerForm.errors?.auth }}</span>
			</p>
		</form>
		<p class="is-success is-paragraph" *ngIf="signedUp">
			Uw account is succesvol aangemaakt.
		</p>
	</ng-container>
	<ng-container buttons>
		<app-fullwidth-button label="Registreren" [disabled]="registerForm.invalid" (click)="register()" inverted>
		</app-fullwidth-button>
		<app-fullwidth-button (click)="auth.signInWithGoogle()" label="Registreer via Google">
			<ng-container icon-left>
				<img src="/assets/icons/google-auth.svg" alt="Google sign in" />
			</ng-container>
		</app-fullwidth-button>
	</ng-container>
</app-auth-layout>
