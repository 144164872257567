import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'distanceTo',
})
export class DistanceToPipe implements PipeTransform {
	private haversine_distance(pos1: google.maps.LatLng, pos2: google.maps.LatLng) {
		const R = 6371.071; // Radius of earth in KM
		const rlat1 = pos1.lat() * (Math.PI / 180); // Convert degrees to radians
		const rlat2 = pos2.lat() * (Math.PI / 180); // Convert degrees to radians
		const difflat = rlat2 - rlat1; // Radian difference (latitudes)
		const difflon = (pos2.lng() - pos1.lng()) * (Math.PI / 180); // Radian difference (longitudes)

		const distanceInKM =
			2 *
			R *
			Math.asin(
				Math.sqrt(
					Math.sin(difflat / 2) * Math.sin(difflat / 2) +
						Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
				)
			);
		return distanceInKM;
	}

	transform(position: google.maps.LatLng, measureDistanceTo: google.maps.LatLng): number {
		return this.haversine_distance(position, measureDistanceTo);
	}
}
