import { Component, HostBinding, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { slideInOutRightAnimation } from '@core/animations';
import { AuthService } from '@core/services/auth/auth.service';
import { passwordPattern } from 'app/config/auth.config';

const registerFormValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
	const errors: ValidationErrors = {};

	const email = control.get('email');
	const password = control.get('password');
	const passwordConfirm = control.get('passwordconfirm');

	const fieldInvalid = (field: AbstractControl) => field.value && field.dirty && field.invalid;

	if (email && password && passwordConfirm) {
		if (fieldInvalid(email)) {
			errors.email = true;
		}
		if (fieldInvalid(password)) {
			errors.password = true;
		}
		if (password.value !== passwordConfirm.value && passwordConfirm.dirty) {
			errors.passwordconfirm = true;
		}
	}

	return errors;
};

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	animations: [slideInOutRightAnimation],
})
export class RegisterComponent implements OnInit {
	@HostBinding('@slideInOutRight') get slideInOutRight() {
		return '';
	}

	emailTo = '';
	signedUp = false;
	// tslint:disable-next-line: member-ordering
	registerForm = new FormGroup(
		{
			email: new FormControl('', { validators: [Validators.required, Validators.email], updateOn: 'blur' }),
			password: new FormControl('', {
				validators: [Validators.required, Validators.pattern(passwordPattern)],
				updateOn: 'blur',
			}),
			passwordconfirm: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
		},
		{ validators: registerFormValidator }
	);

	clearError(error: string) {
		const errs = this.registerForm.errors;
		if (errs && errs[error]) {
			const { [error]: undefined, ...remainingErrors } = errs;
			this.registerForm.setErrors(remainingErrors);
		}
	}

	async register() {
		if (!this.registerForm.valid) {
			return void 0;
		}

		const formValue = this.registerForm.getRawValue();
		const { email, password } = formValue;

		// before we sign up, we verify our email is correct.
		const validityCheck = await this.auth.verifyEmail(email).toPromise();

		if (validityCheck.valid === false) {
			this.auth.setErrorOnControl(this.registerForm, { code: 'auth/register-forbidden' }, 'auth');
		} else {
			this.auth
				.signUpWithEmailAndPassword(email, password)
				.then(() => {
					this.emailTo = email;
					this.registerForm.reset();
					this.signedUp = true;
					// The new user is registered, firebase will auto-login the user.
					// We force a route to '[/]' to let the application route us to the starting page for
					// a logged in user.
					this.router.navigate(['/']);
				})
				.catch(error => this.auth.setErrorOnControl(this.registerForm, error, 'provider'));
		}
	}

	constructor(public auth: AuthService, private router: Router) {}

	ngOnInit(): void {}
}
