<a
	[routerLink]="route"
	routerLinkActive="is-active"
	[attr.disabled]="disabled ? '' : null"
	[routerLinkActiveOptions]="{ exact: true }"
>
	<ng-container *ngIf="icon">
		<ion-icon [name]="icon"></ion-icon>
	</ng-container>
	<ng-content select="[image]"></ng-content>
	<div class="title">{{ text }}</div>
</a>
