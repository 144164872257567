import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwaInstallationModalComponent } from './pwa-installation-modal/pwa-installation-modal.component';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';

const DECLARED_EXPORTS = [PwaInstallationModalComponent];

@NgModule({
	declarations: DECLARED_EXPORTS,
	exports: DECLARED_EXPORTS,
	imports: [CommonModule, PortalModule, OverlayModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OnboardingModule {}
