<label class="label is-h6">
	<span *ngIf="label">{{ label }}</span>
	<div class="input-control" [class.is-small]="theme === 'small'">
		<span class="icon icon-before">
			<ng-content select="[icon-before]"></ng-content>
		</span>
		<input
			[(ngModel)]="value"
			[type]="type"
			[name]="type"
			[placeholder]="placeholder"
			[class.is-error]="error"
			(blur)="onBlur()"
			[attr.readonly]="readonly ? true : null"
		/>
		<span class="icon icon-after">
			<ng-content select="[icon-after]"></ng-content>
		</span>
	</div>
</label>
<p class="is-paragraph is-error" *ngIf="reserveErrorSpace || error">
	<span *ngIf="error">{{ errorMessage }}</span>
</p>
