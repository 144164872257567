<app-input-field
	[(ngModel)]="value"
	[error]="error"
	[errorMessage]="errorMessage"
	[type]="fieldType"
	placeholder=""
	label="Wachtwoord"
	(focusout)="onBlur()"
>
	<ng-container icon-after>
		<ion-icon
			class="ion-icon-toggle"
			*ngIf="fieldType === 'password'"
			name="eye"
			(click)="setFieldType('text')"
		></ion-icon>
		<ion-icon
			class="ion-icon-toggle"
			*ngIf="fieldType === 'text'"
			name="eye-off"
			(click)="setFieldType('password')"
		></ion-icon>
	</ng-container>
</app-input-field>
