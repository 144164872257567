import { OverlayRef } from '@angular/cdk/overlay';
import { PwaInstallationModalComponent } from './pwa-installation-modal.component';
import { Subject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

type ModalAnimation = AnimationEvent & { phaseName: string; toState: string };

export class PwaInstallationModalData {
	platform: string;
	promptEvent: any;
}

export class PwaInstallationModalRef {
	constructor(readonly overlay: OverlayRef) {}

	componentInstance: PwaInstallationModalComponent;
	private _beforeClose = new Subject<void>();
	private _afterClosed = new Subject<void>();

	setComponentInstance(componentIntance: PwaInstallationModalComponent) {
		this.componentInstance = componentIntance;
	}

	close() {
		// Listen for animation 'start' events
		this.componentInstance.animationStateChanged
			.pipe(
				filter((event: ModalAnimation) => event.phaseName === 'start'),
				take(1)
			)
			.subscribe(() => {
				this._beforeClose.next();
				this._beforeClose.complete();
				this.overlay.detachBackdrop();
			});

		// Listen for animation 'done' events
		this.componentInstance.animationStateChanged
			.pipe(
				filter((event: ModalAnimation) => event.phaseName === 'done' && event.toState === 'leave'),
				take(1)
			)
			.subscribe(() => {
				this.overlay.dispose();
				this._afterClosed.next();
				this._afterClosed.complete();

				// Make sure to also clear the reference to the
				// component instance to avoid memory leaks
				// tslint:disable-next-line: no-non-null-assertion
				this.componentInstance = null!;
			});

		// Start exit animation
		this.componentInstance.startExitAnimation();
	}

	afterClosed(): Observable<void> {
		return this._afterClosed.asObservable();
	}

	beforeClose(): Observable<void> {
		return this._beforeClose.asObservable();
	}
}
