import { Injectable, ElementRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NotificationService } from 'shared-library';

@Injectable({
	providedIn: 'root',
})
export class AppUpdateService {
	constructor(private readonly updates: SwUpdate, private readonly notification: NotificationService) {}

	connectToElementRef(elementRef: ElementRef) {
		this.updates.available.subscribe(event => {
			this.showUpdateAlert(elementRef);
		});
	}

	showUpdateAlert(elementRef: ElementRef) {
		this.notification.show(elementRef, {
			headline: 'Er is een update van de applicatie beschikbaar',
			subtext: 'Wilt u nu updaten?',
			okText: 'Ja',
			cancelText: 'Nee',
			action: this.updateApp,
			caller: this,
		});
	}

	updateApp() {
		this.updates.activateUpdate().then(() => document.location.reload());
	}
}
