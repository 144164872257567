import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env';
import { AppModule } from './app/app.module';

if (environment.production) {
	enableProdMode();
}

// tslint:disable-next-line: no-console
console.log(`Ratio Monitor (${environment.name}) (v ${environment.version}).`);

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
