import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { PwaInstallationModalComponent } from './pwa-installation-modal.component';
import { PwaInstallationModalData, PwaInstallationModalRef } from './pwa-installation-modal.config';

@Injectable({
	providedIn: 'root',
})
export class PwaInstallationModalService {
	overlayRef: OverlayRef | null;

	close() {
		if (this.overlayRef) {
			this.overlayRef.dispose();
			this.overlayRef = null;
		}
	}

	show(data: PwaInstallationModalData) {
		const positionStrategy = this.getPosition();
		const overlayRef = this.overlay.create({
			hasBackdrop: true,
			backdropClass: 'backdrop',
			panelClass: 'panel',
			scrollStrategy: this.overlay.scrollStrategies.block(),
			positionStrategy,
		});
		const pwaInstallationModalRef = new PwaInstallationModalRef(overlayRef);

		const injector = this.getInjector(data, pwaInstallationModalRef, this.parentInjector);
		const modalPortal = new ComponentPortal(PwaInstallationModalComponent, null, injector);
		const containerRef = overlayRef.attach(modalPortal);

		// connect the newly created instance to the Modal Ref so we can attach to it's event bus.
		pwaInstallationModalRef.setComponentInstance(containerRef.instance);

		return pwaInstallationModalRef;
	}

	private getInjector(
		data: PwaInstallationModalData,
		pwaInstallationModalRef: PwaInstallationModalRef,
		parentInjector: Injector
	) {
		const tokens = new WeakMap();

		tokens.set(PwaInstallationModalData, data);
		tokens.set(PwaInstallationModalRef, pwaInstallationModalRef);

		return new PortalInjector(parentInjector, tokens);
	}

	private getPosition() {
		return this.overlay.position().global().centerHorizontally().bottom();
	}

	constructor(private overlay: Overlay, private parentInjector: Injector) {}
}
