<app-auth-layout>
	<ng-container hero>
		<div class="ratio-logo">
			<img src="https://ratiosurvey.nl/wp-content/themes/ratiosurvey/images/ratiosurvey-logo.svg" alt="Logo" />
		</div>
	</ng-container>
	<ng-container form>
		<h5 class="is-h5">Inloggen via Email</h5>
		<form [formGroup]="loginForm">
			<app-input-field
				formControlName="email"
				[error]="loginForm.errors?.email"
				[errorMessage]="auth.errorMap['email/invalid']"
				type="email"
				placeholder="email@address.com"
				label="Email adres"
			>
				<ng-container icon-before>
					<ion-icon name="mail"></ion-icon>
				</ng-container>
			</app-input-field>
			<app-password-field
				formControlName="password"
				[error]="passwordError$ | async"
				[errorMessage]="auth.errorMap['password/pattern']"
				placeholder=""
				label="Wachtwoord"
				(focusin)="clearError('password')"
				(keydown.enter)="loginEmailPassword()"
			>
			</app-password-field>
			<p class="is-paragraph is-error">
				<span *ngIf="loginForm.errors?.provider">{{ loginForm.errors?.provider }}</span>
				<span *ngIf="loginForm.errors?.auth">{{ loginForm.errors?.auth }}</span>
			</p>
			<div class="forgot-pass-link">
				<a [routerLink]="['/forgotpassword']">Wachtwoord vergeten?</a>
			</div>
			<div class="register-link">
				<a [routerLink]="['/register']">Ik heb nog geen account</a>
			</div>
		</form>
	</ng-container>
	<ng-container buttons>
		<app-fullwidth-button
			[disabled]="loginForm.invalid && loginForm.touched"
			(click)="loginEmailPassword()"
			label="Inloggen"
			inverted
		>
		</app-fullwidth-button>
		<app-fullwidth-button (click)="auth.signInWithGoogle()" label="Log in via Google">
			<ng-container icon-left>
				<img src="/assets/icons/google-auth.svg" alt="Google sign in" />
			</ng-container>
		</app-fullwidth-button>
	</ng-container>
</app-auth-layout>
