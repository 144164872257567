// import the required animation functions from the angular animations module
import { animate, style, transition, trigger } from '@angular/animations';

const TRANSITION = `.33s cubic-bezier(0.215, 0.610, 0.355, 1)`;

export const slideInOutRightAnimation = trigger('slideInOutRight', [
	transition(':enter', [
		style({ transform: 'translateX(100%)' }),
		animate(TRANSITION, style({ transform: 'translateX(0%)' })),
	]),
	transition(':leave', [
		style({ transform: 'translateX(0%)' }),
		animate(TRANSITION, style({ transform: 'translateX(100%)' })),
	]),
]);

export const slideOutLeftAnimation = trigger('slideOutLeft', [
	transition(':leave', [
		style({ transform: 'translateX(0%)' }),
		animate(TRANSITION, style({ transform: 'translateX(-100%)' })),
	]),
]);
