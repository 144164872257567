import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { StorageService } from '@core/services/storage/storage.service';
import { pwaInstallationAnimation } from './pwa-installation-modal.animation';
import { PwaInstallationModalData, PwaInstallationModalRef } from './pwa-installation-modal.config';
import { pwaInstallPromptKey } from './pwa.config.json';

@Component({
	selector: 'app-pwa-installation-modal',
	templateUrl: './pwa-installation-modal.component.html',
	styleUrls: ['./pwa-installation-modal.component.scss'],
	animations: [pwaInstallationAnimation.fadeModal, pwaInstallationAnimation.fadeBackdrop],
})
export class PwaInstallationModalComponent implements OnInit {
	animationStateChanged = new EventEmitter<AnimationEvent>();
	animationState = 'void';

	async close() {
		this.ref.close();
		await this.storageService.writeToStorage(pwaInstallPromptKey, new Date().getTime());
	}

	install() {
		if (this.data.promptEvent) {
			this.data.promptEvent.prompt();
		} else {
			alert(
				'We kunnen Ratio Monitor momenteel niet installeren.\r\nHet lijkt er op dat de app al geinstalleerd is op dit apparaat.'
			);
		}
		this.close();
	}

	onAnimationStart(event: AnimationEvent) {
		this.animationStateChanged.emit(event);
	}

	onAnimationDone(event: AnimationEvent) {
		this.animationStateChanged.emit(event);
	}

	startExitAnimation() {
		this.animationState = 'leave';
	}

	constructor(
		@Inject(PwaInstallationModalData) readonly data: PwaInstallationModalData,
		readonly ref: PwaInstallationModalRef,
		private readonly storageService: StorageService
	) {}

	ngOnInit(): void {
		this.animationState = 'enter';
	}
}
