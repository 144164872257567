import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { slideInOutRightAnimation } from '@core/animations';
import { AuthService } from '@core/services/auth/auth.service';
import { map, pluck } from 'rxjs/operators';

@Component({
	selector: 'app-forgotpassword',
	templateUrl: './forgotpassword.component.html',
	styleUrls: ['./forgotpassword.component.scss'],
	animations: [slideInOutRightAnimation],
})
export class ForgotpasswordComponent implements OnInit {
	email = new FormControl('', { validators: [Validators.required, Validators.email], updateOn: 'blur' });
	emailSent = false;
	emailSentTo = '';

	@HostBinding('@slideInOutRight') get slideInOutRight() {
		return '';
	}

	backButtonRoute$ = this.route.queryParams.pipe(
		pluck<Params, string>('referrer'),
		map(referrer => {
			if (referrer && referrer === 'profile') {
				return '/profile';
			} else {
				return '/login';
			}
		})
	);

	sendResetEmail() {
		this.auth
			.sendPasswordResetEmail(this.email.value)
			.then(() => {
				this.emailSentTo = this.email.value;
				this.email.reset();
				this.emailSent = true;
			})
			.catch(error => this.auth.setErrorOnControl(this.email, error));
	}

	constructor(private route: ActivatedRoute, public auth: AuthService) {}

	ngOnInit(): void {}
}
