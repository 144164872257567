import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guard/auth.guard';
import { LoginGuard } from '@core/guard/login.guard';
import { ForgotpasswordComponent } from './routes/forgotpassword/forgotpassword.component';
import { LoginComponent } from './routes/login/login.component';
import { NotFoundComponent } from './routes/not-found/not-found.component';
import { RegisterComponent } from './routes/register/register.component';

// Todo: Refactor authentication pages into an encapsulated module.
const routes: Routes = [
	{ path: '', redirectTo: 'map', pathMatch: 'full' },
	{
		path: 'map',
		loadChildren: () => import('./routes/map/map.module').then(m => m.ProjectModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'profile',
		loadChildren: () => import('./routes/profile/profile.module').then(m => m.ProfileModule),
		canActivate: [AuthGuard],
	},
	{ path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
	{ path: 'forgotpassword', component: ForgotpasswordComponent },
	{ path: 'register', component: RegisterComponent, canActivate: [LoginGuard] },
	{
		path: 'privacyverklaring',
		loadChildren: () => import('./routes/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
	},
	{
		path: 'algemene-informatie',
		loadChildren: () => import('./routes/about/about.module').then(m => m.AboutModule),
	},
	{ path: '404', component: NotFoundComponent },
	{ path: '*', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
