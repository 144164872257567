import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-fullpage-overlay',
	templateUrl: './fullpage-overlay.component.html',
	styleUrls: ['./fullpage-overlay.component.scss'],
})
export class FullpageOverlayComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
