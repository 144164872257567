import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ApiService } from '@core/api/api.service';
import { notNullOrUndefined } from '@core/functions/helpers';
import { AuthErrorMap, ControlInvalidDuration } from 'app/config/auth.config';
import { auth, UserInfo } from 'firebase';
import { Observable } from 'rxjs';
import { filter, flatMap, map, shareReplay, take } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private authUserWithClaims$ = this.firebaseAuth.authState.pipe(
		filter(user => Boolean(user)),
		flatMap(async user => {
			if (!user) {
				return null;
			}

			await this.api.auth
				.setCustomClaims()
				.toPromise()
				.catch(() => {});
			const payload = await user.getIdTokenResult(true);

			// if (!payload.claims.validUser) {
			// 	this.signOut();
			// 	return null;
			// }

			return user;
		}),
		shareReplay(1)
	);

	public userProfile$ = this.storage.useCacheStrategy(this.userProfile(), 'userProfile');
	public uid$ = this.userProfile$.pipe(
		map(profile => profile.uid),
		shareReplay(1)
	);
	public authState$ = this.firebaseAuth.authState;
	public isAuthenticated$ = this.authState$.pipe(map(u => !!u));
	public errorMap = AuthErrorMap;
	public onIdTokenChanged = this.firebaseAuth.onIdTokenChanged;

	setErrorOnControl(control, error, errorKey = 'provider', timeout = true) {
		control.setErrors({ [errorKey]: this.errorMap[error.code] || error.message });
		if (timeout) {
			setTimeout(() => {
				control.setErrors({});
				control.updateValueAndValidity();
			}, ControlInvalidDuration);
		}
	}

	isAuthenticated() {
		return this.isAuthenticated$.pipe(take(1));
	}

	signUpWithEmailAndPassword(email: string, password: string) {
		return this.firebaseAuth.createUserWithEmailAndPassword(email, password);
	}

	signInWithEmailAndPassword(email: string, password: string) {
		return this.firebaseAuth.signInWithEmailAndPassword(email, password);
	}

	sendPasswordResetEmail(email: string) {
		return this.firebaseAuth.sendPasswordResetEmail(email);
	}

	signInWithGoogle() {
		const provider = new auth.GoogleAuthProvider();
		provider.setCustomParameters({
			prompt: 'select_account',
		});

		return this.firebaseAuth.signInWithRedirect(provider);
	}

	signOut() {
		return this.firebaseAuth.signOut().then(() => this.storage.clearStorage().toPromise());
	}

	routeToLogin() {
		this.router.navigate(['/login']);
	}

	verifyEmail(email: string) {
		return this.api.auth.validateEmail(email);
	}

	private userProfile(): Observable<UserInfo> {
		return this.authUserWithClaims$.pipe(
			filter(notNullOrUndefined),
			map(u => ({
				displayName: u.displayName,
				email: u.email,
				phoneNumber: u.phoneNumber,
				photoURL: u.photoURL,
				providerId: u.providerId,
				uid: u.uid,
			})),
			shareReplay(1)
		);
	}

	constructor(
		private storage: StorageService,
		private firebaseAuth: AngularFireAuth,
		private api: ApiService,
		private router: Router
	) {}
}
