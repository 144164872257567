import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-back-button',
	templateUrl: './back-button.component.html',
	styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
	@Input() route = '/';
	@Input() text = '';
	@Input() theme: 'light' | 'dark' = 'dark';
	@Input() disableRouting = false;

	public get isLightTheme(): boolean {
		return this.theme === 'light';
	}

	constructor() {}

	ngOnInit(): void {}
}
