import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private auth: AuthService, private router: Router) {}

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		return this.auth.isAuthenticated().pipe(
			map(authenticated => {
				if (!authenticated) {
					this.router.navigate(['login']);
					return false;
				}
				return true;
			})
		);
	}
}
