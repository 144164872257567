import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-navbar-route',
	templateUrl: './navbar-route.component.html',
	styleUrls: ['./navbar-route.component.scss'],
})
export class NavbarRouteComponent implements OnInit {
	@Input() text = 'text';
	@Input() icon = '';
	@Input() route = '/';
	@Input() disabled = false;

	constructor() {}

	ngOnInit(): void {}
}
