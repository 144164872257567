import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BottomSheetComponent } from './bottom-sheet.component';
import { BottomSheetDirective } from './bottom-sheet.directive';

export const DECLARED_EXPORTS = [BottomSheetComponent];

@NgModule({
	declarations: [...DECLARED_EXPORTS, BottomSheetDirective],
	imports: [CommonModule],
	exports: DECLARED_EXPORTS,
})
export class BottomSheetModule {}
