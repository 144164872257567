import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-fullwidth-button',
	templateUrl: './fullwidth-button.component.html',
	styleUrls: ['./fullwidth-button.component.scss'],
})
export class FullwidthButtonComponent implements OnInit {
	@Input() label = 'Button text';
	// tslint:disable-next-line: no-input-rename
	@Input('inverted') invertedAttr: boolean;
	@Input() disabled = false;

	inverted = false;

	constructor() {}

	ngOnInit(): void {
		this.inverted = this.invertedAttr !== undefined;
	}
}
