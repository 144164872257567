import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-sensor-dot',
  templateUrl: './sensor-dot.component.html',
  styleUrls: ['./sensor-dot.component.scss']
})
export class SensorDotComponent implements OnInit {

  @HostBinding('class.is-selected') @Input() selected = false;

  constructor() { }

  ngOnInit(): void {
  }

}
