import { AnimationTriggerMetadata, state, style, trigger, transition, animate } from '@angular/animations';

const ANIMATION = '225ms ease-out';

export const pwaInstallationAnimation: {
	readonly fadeModal: AnimationTriggerMetadata;
	readonly fadeBackdrop: AnimationTriggerMetadata;
} = {
	fadeModal: trigger('panelAnimation', [
		state('void', style({ opacity: 0, transform: 'translateY(30px)' })),
		state('enter', style({ opacity: 1, transform: 'translateY(0)' })),
		state('leave', style({ opacity: 0, transform: 'translateY(30px)' })),
		transition('* => *', animate(ANIMATION)),
	]),
	fadeBackdrop: trigger('backdropAnimation', [
		state('void', style({ opacity: 0 })),
		state('enter', style({ opacity: 1 })),
		state('leave', style({ opacity: 0 })),
		transition('* => *', animate(ANIMATION)),
	]),
};
