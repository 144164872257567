/**
 * Function to facilitate generic typing of null or undefined in observables
 * @param val Value for a typed return of null or undefined
 */
export function notNullOrUndefined<T>(val: T | undefined | null): val is T {
	return val !== undefined && val !== null;
}

export function notEmptyString(val) {
	return val !== '';
}
