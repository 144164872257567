<app-auth-layout>
	<ng-container hero>
		<div id="hero">
			<div id="header">
				<app-back-button [route]="backButtonRoute$ | async" text="Terug"></app-back-button>
			</div>
			<div class="hero-text">
				<div class="tagline">
					<h3 class="is-h3">Wachtwoord<br />vergeten?</h3>
					<p class="is-paragraph">Geen zorgen, we sturen<br />herstelinstructies via email.</p>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container form>
		<app-input-field
			type="email"
			[formControl]="email"
			placeholder="email@address.com"
			label="Email adres"
			[error]="email.dirty && email.invalid"
			[errorMessage]="email.errors?.provider || auth.errorMap['email/invalid']"
		>
			<ng-container icon-before>
				<ion-icon name="mail"></ion-icon>
			</ng-container>
		</app-input-field>
		<p class="is-success is-paragraph" *ngIf="emailSent">
			Er is een email met herstelinstructies verzonden naar {{ emailSentTo }}.
		</p>
	</ng-container>
	<ng-container buttons>
		<app-fullwidth-button (click)="sendResetEmail()" label="Verzenden" inverted> </app-fullwidth-button>
	</ng-container>
</app-auth-layout>
