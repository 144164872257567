import { version } from '../../package.json';
import { Environment } from './environment.config';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
	production: false,
	firebase: {
		apiKey: 'AIzaSyBohMkUuL3VOW0jFN3FUwQR4FkcGB35qJw',
		authDomain: 'ratiomonitoring-507-dev.firebaseapp.com',
		databaseURL: 'https://ratiomonitoring-507-dev.firebaseio.com',
		projectId: 'ratiomonitoring-507-dev',
		storageBucket: 'ratiomonitoring-507-dev.appspot.com',
		messagingSenderId: '769585518843',
		appId: '1:769585518843:web:f828e50dd59b8ed62ab85e',
	},
	apiEndpoint: 'https://dev.api.ratiomonitor.app',
	version,
	name: 'DEV',
};

// export const environment: Environment = {
// 	production: true,
// 	firebase: {
// 		apiKey: 'AIzaSyBUPNgnbpAssckiPQkwZSB_rY8xSIp-XAE',
// 		authDomain: 'ratiomonitoring.firebaseapp.com',
// 		databaseURL: 'https://ratiomonitoring.firebaseio.com',
// 		projectId: 'ratiomonitoring',
// 		storageBucket: 'ratiomonitoring.appspot.com',
// 		messagingSenderId: '508307314688',
// 		appId: '1:508307314688:web:1cb494bea3e2fa04',
// 	},
// 	apiEndpoint: 'https://prod.api.ratiomonitor.app',
// 	version,
// 	name: '',
// };

export const FS_COLLECTIONS = {
	PROJECTS: 'projects',
	SENSORS: 'sensors',
	ALARMS: 'alarms',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
