<ng-container *ngIf="disableRouting; then routingDisabled; else routingEnabled"> </ng-container>

<ng-template #routingDisabled>
	<a class="nav-back" [class.is-light-theme]="isLightTheme">
		<ng-container [ngTemplateOutlet]="content"></ng-container>
	</a>
</ng-template>
<ng-template #routingEnabled>
	<a class="nav-back" [routerLink]="[route]" [class.is-light-theme]="isLightTheme">
		<ng-container [ngTemplateOutlet]="content"></ng-container>
	</a>
</ng-template>
<ng-template #content>
	<ion-icon name="arrow-back-outline"></ion-icon>
	<span class="is-paragraph" *ngIf="text">
		{{ text }}
	</span>
</ng-template>
