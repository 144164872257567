import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { flatMap, switchMap, take } from 'rxjs/operators';

@Injectable()
export class FirebaseAuthInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (environment.production !== false && !req.url.startsWith(environment.apiEndpoint)) {
			return next.handle(req);
		}
		// Get the auth token (promise) from the service and turn into an observable
		return this.fireAuth.user.pipe(
			take(1),
			flatMap(async user => {
				if (!user) {
					return req;
				}

				const token = await user.getIdToken();

				if (!token) {
					return req;
				}
				// Clone the request and replace the original headers with
				// cloned headers, updated with the authorization.

				const authReq = req.clone({
					headers: req.headers.set('Authorization', `Bearer ${token}`),
				});

				return authReq;
			}),
			switchMap(authReq => {
				// Send cloned request with header to the next handler.
				return next.handle(authReq);
			})
		);
	}

	constructor(private fireAuth: AngularFireAuth) {}
}

export const FirebaseAuthInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: FirebaseAuthInterceptor,
	multi: true,
};
